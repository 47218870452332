.headerNav {
  display: block;
  flex: 1;
  height: 100%;

  .navList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    margin-left: 4%;

    li {
      height: 100%;

      .link {
        font-weight: 500;
        padding: 0.5rem 1rem;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        color: #fff;

        &:hover {
          color: rgba(255, 255, 255, 0.7);
        }
      }

      .link_children {
        font-weight: 500;
        position: relative;
        height: 100%;
        box-sizing: border-box;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        color: #fff;
        cursor: pointer;

        .icon {
          margin-left: 10px;
          vertical-align: middle;
        }

        .icon_tran {
          transform: rotateX(180deg);
          transition: 0.3s all;
        }

        &:hover {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .headerNav {
    display: none;
  }
}
