.type1 {
  min-height: 400px !important;
  position: absolute;
  top: 4.75rem;
  z-index: 100;
  background-color: #fff;
  overflow: scroll;
  transition: height 0.3s ease-in-out;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 1rem;
  display: flex;

  .left_wrapper {
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
    width: 17rem;
    height: 100%;

    .title {
      padding: 0.5rem 0;
      font-size: 0.875rem;
      color: grey;
      font-weight: 600;
    }

    .item {
      display: flex;
      box-sizing: border-box;

      .link {
        display: inline-block;
        width: 100%;
        padding: 0.75rem 0.5rem;
        color: #000;

        &:hover {
          background-color: #e8e8e8;
          border-radius: 0.1875rem;
        }
      }
    }
  }

  .right_wrapper {
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
    width: 17rem;
    margin-left: 10px;
    height: 100%;

    .title {
      padding: 0.5rem 0;
      font-size: 0.875rem;
      color: grey;
      font-weight: 600;
    }

    .item {
      display: flex;
      box-sizing: border-box;

      .link {
        display: inline-block;
        width: 100%;
        padding: 0.75rem 0.5rem;
        color: #000;

        &:hover {
          background-color: #e8e8e8;
          border-radius: 0.1875rem;
        }
      }
    }
  }
}
